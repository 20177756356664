@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');
@import "~admin-lte/plugins/fontawesome-free/css/all.min.css";
@import "~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css";
@import '~react-dates/lib/css/_datepicker.css';
@import '~bootstrap/scss/functions';
@import "styles/custom-variables";  // Custom Variables (Bootstrap)

// Admin LTE 3 Variables Override
@import "styles/theme-variables"; // Theme variable Override

@import "styles/font.scss";// Custom font


// Variables and Mixins
// ---------------------------------------------------
@import '~admin-lte/build/scss/mixins';
@import '~bootstrap/scss/bootstrap';

@import '~admin-lte/build/scss/parts/core';
@import '~admin-lte/build/scss/parts/components';
@import '~admin-lte/build/scss/parts/extra-components';
@import '~admin-lte/build/scss/parts/pages';
@import '~admin-lte/build/scss/parts/plugins';
@import '~admin-lte/build/scss/parts/miscellaneous';


@import "styles/common.scss";  // Custom Scss  - you can update this file
@import "styles/nprogress";

