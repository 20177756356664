.main-header {
    .dropdown-menu {
        margin-top: 10px;
        top: 200%;
        pointer-events: none;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        display: block;
        box-shadow: none;
        &.show{
            top: 100%;
            pointer-events: auto;
            opacity: 1;
        }
        .dropdown-item{
            white-space: normal;
            > i{
                margin-right: 10px;
            }
        }
        h4{
            padding: $dropdown-padding-y $dropdown-item-padding-x;
            text-transform: uppercase;
            font-weight: $font-weight-semi-bold;
        }
        .close-drop{
          color: $base-color;
          right: 0;
          position: absolute;
          top: 0;
          width: 35px;
          height: 35px;
          background-color: $gray-300;
          line-height: 16px;
          cursor: pointer;
          z-index: 1;
          padding: 10px 8px;
          text-align: center;
        }
    }
    .dropdown{
      @include media-breakpoint-down(md) {
        position: static;
      }
    }
}

  // Dropdown Sizes
  .dropdown-menu-xl {
    max-width: 420px;
    min-width: 360px;
    padding: 0;
  
    .dropdown-divider {
      margin: 0;
    }
  
    .dropdown-item {
      padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    }
  
    p {
      margin: 0;
      white-space: normal;
    }
  }

// Dropdown Sizes
.dropdown-menu-xxl {
    max-width: 900px;
    min-width: 360px;
    width: 900px;
    padding: 15px;
    @include media-breakpoint-only(lg){
      max-width: 700px;
      width: 700px;
    }
    .dropdown-item {
        padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    }
    &.quick-link-dropdown{
      @include media-breakpoint-down(md) {
        min-width: 100%;
        width: 100%;
        .row{
          .col{
              min-width: 50%;
          }
        }
      }
    }
}
