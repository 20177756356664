.btn {
  line-height: 1;
  border-radius: 0;
 // text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  &.simple-btn{
    text-transform: inherit;
    font-weight: normal;
  }
  &.icon-left{
    i{
      padding-right: 8px;
      @media (max-width:360px){
        padding-right: 5px;
      }
    }
  }
  &.icon-right{
    i{
      padding-left: 8px;
      @media (max-width:360px){
        padding-left: 5px;
      }
    }
  }
}

// Make a button look and behave like a link
.btn-transparent {
  color: $base-color;
  @include hover() {
    color: $base-color;
    background-color: $gray-300;
  }
  &:focus,
  &.focus {
    color: $base-color;
    background-color: $gray-300;
  }
  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $base-color;
    background-color: $transparent;
  }
}

.btn-lightgreen {
  background-color: rgba($primary, .2);
  border-color: rgba($primary, .6);
  color: $base-color;

  &:hover,
  &:active,
  &.hover {
    background-color: rgba($primary, 1);
    color: $white;
  }
}