.lottery_table_highlighted {
  background-color: #3F7DC5 !important;

  td {
    color: #fff;
  }
}
.lottery_table_danger {
  td {
    color: $danger;
  }
}