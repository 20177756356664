.background-container {
    position: fixed;
    top: 60px;
    right: 0;
    height: auto;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9999;
    max-height: 100%;
    overflow-y: auto;
}
.c-toast {
    top: 0;
    right: 0;
    margin: 1rem;
    min-width: 30vh !important;
}