.hamburger-box {
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  margin: 8px 0;

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 24px;
    height: 2px;
    background-color: $base-color;
    border-radius: 5px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;

    &::before, &::after {
      content: "";
      display: block;
    }

    &::before {
      top: -8px;
    }

    &::after {
      bottom: -8px;
    }
  }
}

.customer-cupport-bar {
  @include media-breakpoint-down(lg) {
    margin: 0px;
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  }

  .support-bar {
    margin-left: 50px;
    color: $primary;
    padding: 0.688rem 0.625rem;
    display: block;
    line-height: 18px;
    background-color: $white;
    text-align: center;
    @include media-breakpoint-down(lg) {
      margin-left: 0;
    }
    @include media-breakpoint-only(xl) {

      max-width: 220px;
      padding: 0.388rem 0.625rem;
    }
  }
}

.top-nav-refer {
  border: 1px solid $white !important;
  line-height: 1;
  margin-left: 0.813rem;
}


.main-header {
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.05);
  height: 64px;
  &.navbar {
    padding: 0.66667rem 1.5rem;
  }
  .navbar-nav {
    align-items: center;

    .nav-link {
      padding: 0.625rem 0.5rem;
      font-size: 0.938rem;
      height: auto;
      @include media-breakpoint-down(xs) {
        padding: 0.325rem 0.5rem;
        font-size: 0.8125rem;
      }
      @media (max-width: 360px) {
        padding: 0.325rem 0.3rem;
      }
      &.arrow {
        i {
          padding-left: 0.438rem;
          @include media-breakpoint-down(xs) {
            padding-left: 0;
          }
          &.user-icon {
            padding-right: 0.438rem;
            @include media-breakpoint-down(xs) {
              padding-right: 0;
            }
          }
          &.fa-store{
            padding-right: 0.438rem;
          }
        }
      }
      &.store-link{
        &.arrow {
          i {
            padding-left: 0.438rem;
          }
        }
      }
    }

    .staging-btn {
      max-width: 150px;
      width: 100%;
      padding-right: 0.5rem;

      .login-by-btn {
        background-color: $gray-800;
        padding: 10px;
        color: $white;
        font-weight: $font-weight-bold;
        text-align: center;
        max-width: 150px;
        width: 100%;
        display: inline-block;
        line-height: 1.6;
      }
    }

    .dropdown-menu {
      margin-top: 10px;
      top: 200%;
      pointer-events: none;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      display: block;
      box-shadow: none;
      @include media-breakpoint-down(md) {
        margin-top: 0;
      }
      &.show {
        top: 100%;
        pointer-events: auto;
        opacity: 1;

        &::after {
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 1000;
          display: block;
          width: 100%;
          height: 100%;
          position: fixed;
        }
      }

      .dropdown-item {
        > i {
          margin-right: 10px;
        }
      }
    }
  }
  @include media-breakpoint-down(md){
    position: sticky;
    top: 0;
  }
}

