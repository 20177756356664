.main-sidebar{
    border-right: 1px solid $gray-300;
    transition: transform .3s ease-in-out, width .3s ease-in-out;
    
    .brand-link {
        background-color: $base-color;
        padding: 1.073rem 0;
        height: 64px;
        box-shadow: 0 5px 15px 0 rgba(0,0,0,.05);
        border-bottom: 1px solid $base-color;
        @at-root .layout-fixed{
            .brand-link{
                width: $sidebar-width - 1px;
            }
        }
        .brand-image {
            float: none;
            margin-right: .3rem;
        }
    }
    &.sidebar-collapse.layout-fixed {
        .brand-link {
            .brand-image{
                margin-left: 1.2rem;
            }
        }
    }
    .sidebar {
        overflow-x: hidden;
        .nav-legacy {
            margin: 0 -0.5rem 0;
            .sidebar-mini.sidebar-collapse.text-sm &,
            .sidebar-mini-md.sidebar-collapse.text-sm & {
                > .nav-item > .nav-link {
                .nav-icon {
                    margin-left: .55rem;
                }
                }
            }
        }
        .nav-sidebar {
            &.nav-legacy > .nav-item {
                & > .nav-link.active {
                    border-bottom-color: $gray-300;
                    border-left: 0.2rem solid $base-color;
                    .nav-icon {
                        margin-left: calc(.75rem - 0px);
                    }
                }
            }
            .menu-open{
                > .nav-link {
                    i.right {
                        transform: rotate(90deg);
                    }
                }
            }
            .nav-link > .right,
            .nav-link > p > .right {
                top: 0.95rem;
            }
            .nav-item {
                &.menu-open{
                    > .nav-link{
                        border-left: .2rem solid $base-color;
                    }
                }
                > .nav-link {
                    padding: 0.9rem 0.8rem;
                    border-bottom: 1px solid $gray-300;
                    border-left: 0.2rem solid transparent;
                    p{
                        padding-left: 8px;
                    }
                    .nav-icon{
                        vertical-align: middle;
                    }
                }
            }
        }
        .nav-pills {
            .nav-link {
                border-radius: 0;
            }
        }
    }
}


:not(.layout-fixed) {
    .main-sidebar {
        min-height: calc(100% - 48px);
    }
}
