.DateRangePicker, .DateRangePickerInput {
    @include media-breakpoint-down(md){
        display: block;
    }
}
.DateInput_input {
    display: block;
    width: 100%;
    padding: $input-padding-y $input-padding-x;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    padding: 11px 11px 9px;
    @include box-shadow($input-box-shadow);
    @include transition($input-transition);
}

.DateInput_input__small {
    font-size: 14px;
    line-height: 1;
    letter-spacing: normal;
    padding: 11px 7px 8px;
}
.DateRangePickerInput_calendarIcon, .SingleDatePickerInput_calendarIcon {
    position: relative;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    line-height: 1;
    background-color: $input-group-addon-bg;
    border-right: $input-border-width solid $input-group-addon-border-color;
    margin: 0 5px 0 0;
    height: 38px;
    @include font-size($input-font-size);
    padding: 0.25rem 0.5rem;
    &:after {
        content: "\f073";
        
    }
}
.CalendarDay__selected_span {
    background: #00dccb;
    border: 1px double $primary;
}
.CalendarDay__selected_span{
    &:active, &:hover {
        background: $primary;
        border: 1px double $primary;
    }
}
.DateRangePickerInput_arrow {
    position: relative;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    line-height: 1;
    width: 12px;
    font-weight: 900;
    font-size: 0.8rem;
    &:after {
        content: "\f061";
        position: absolute;
        left: 0;
        background: $white;
        color: $input-color;
        width: 12px;
        height: auto;
        
    }
}
.SingleDatePicker_picker {
    z-index: 2;
}
.SingleDatePicker{
    .DateInput{
        width: auto;
    }
}

